<template>
  <div>Instrumento: {{ name }}</div>
</template>

<script>
export default {
    data() {
        return {
            name: 'Examen'
        }
    }
}
</script>

<style>

</style>